import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";

export default function SelectBox({ data, handleSelectChange }) {
    console.log("data SelectBox: ", data)
    return (
        <FormControl fullWidth sx={{ mb: 2 }}>
            {data.label !== "" &&
                <InputLabel>{data.label}</InputLabel>
            }
            <Select
                id={data.name}
                value={data.value}
                label={data.label}
                onChange={(e) => handleSelectChange(e, data.name)}
            >
                {data.items && data.items.length > 0 && data.items.map((val, index) => {
                    return (
                        <MenuItem value={val.value} key={index.toString()}>{val.name}</MenuItem>
                    )
                })}
                {data.items && data.items.length == 0 ?
                    <MenuItem value="" disabled>No option</MenuItem>
                    : null
                }
            </Select>
            {data.name == "nav_menu" ?
                <FormHelperText sx={{ m: 0, mt:1 }}><a href="/admin/store/menus" target="_blank" style={{ color: "blue" }}>Add menu</a></FormHelperText>
                : null
            }
        </FormControl>
    )
}